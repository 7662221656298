import React, { useState } from "react";
import { Link } from "react-router-dom";

const Menu = (props) => {
  const { menu } = props;
  const { data } = menu || [];
  const [isMobile, setMobile] = useState(false);

  const handleMobileMenu = () => {
    setMobile(!isMobile);
  };

  return (
    <React.Fragment>
      <button className="mobile-menu-btn d-none" onClick={handleMobileMenu}>
        <span className="material-symbols-outlined">menu</span>
      </button>
      <div className={"navbar" + (isMobile ? " show-mobile-menu" : "")}>
        <ul className="list-unstyled p-0 m-0 d-flex align-items-center">
          <li>
            <Link to="/" className="d-flex" onClick={handleMobileMenu}>
              <span
                style={{
                  fontSize: "22px",
                  marginRight: "8px",
                  color: "rgba(0, 167, 111)",
                }}
                className="material-symbols-outlined"
              >
                home
              </span>
            </Link>
          </li>
          <li>
            <Link to="/about-us" onClick={handleMobileMenu}>
              {data && data[1]?.title}
            </Link>
          </li>
          <li>
            <Link
              to="https://aarogyaone.in/blog/"
              target="_blank"
              onClick={handleMobileMenu}
            >
              {data && data[2]?.title}
            </Link>
          </li>
          <li>
            <Link to="/abdm" onClick={handleMobileMenu}>
              {data && data[3]?.title}
            </Link>
          </li>
          <li>
            <Link to="/contact-us" onClick={handleMobileMenu}>
              {data && data[4]?.title}
            </Link>
          </li>
          <li>
            <Link
              className="btn btn-primary text-white"
              style={{ padding: "6px 12px" }}
              target="_blank"
              to="https://play.google.com/store/apps/details?id=com.jdeanzhealthtech.aarogyaone&pcampaignid=web_share/"
            >
              {data && data[5]?.title}
            </Link>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default Menu;
