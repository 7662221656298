import React from "react";
import { Link } from "react-router-dom";

const HomeBlogs = ({ blogs }) => {
  const { data } = blogs || [];
  return (
    <div className="home-blogs">
      <div className="container">
        <h2 className="text-center">{blogs?.title}</h2>
        <h5 className="mb-5">{blogs?.subtitle}</h5>

        <div className="row pt-3">
          {data &&
            data.map((x, key) => (
              <div key={key} className="col-lg-3 mb-3">
                <Link to={x?.url} target="_blank">
                  <div className="shadow1">
                    <img src={x?.image} alt={x?.title} className="w-100" />
                    <div className="p-4">
                      <h4 className="mb-3">{x?.title}</h4>
                      <p className="text-secondary">{x?.desc}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>

        <div className="d-flex justify-content-center">
          <Link
            className="btn btn-outline-secondary mt-5 ps-4 pr-4 d-flex"
            to="https://aarogyaone.in/blog/"
            target="_blank"
          >
            {blogs?.more}{" "}
            <span className="material-symbols-outlined">chevron_right</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeBlogs;
